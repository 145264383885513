import { Box, Checkbox, Flex, Label, Spinner, Text } from '@asktia/tia-ui'
import { format } from 'date-fns'
import * as ThemeUI from 'theme-ui'
import { FC, useState, useLayoutEffect } from 'react'
import { ArrayField, useFieldArray, useFormContext } from 'react-hook-form'
import { Divider } from 'src/components/Blocks'
import { PlusIcon } from 'src/components/Blocks/Icons'
import { useAmpli } from 'src/hooks'
import { lastTouchedByPatient } from 'src/flows/questionnaires/MedicalHistory/pages/Immunizations/helpers'
import { useCreateImmunizations } from 'src/flows/questionnaires/MedicalHistory/pages/Immunizations/hooks/useCreateImmunizations'
import { useEditImmunization } from 'src/flows/questionnaires/MedicalHistory/pages/Immunizations/hooks/useEditImmunization'
import { Bundle, Immunization } from '@medplum/fhirtypes'

export interface ImmunizationFieldProps {
    label: string
    name: string
    immunizationCode: string
    immunizationDisplay: string
    immunizationSystem: string
    immunizations: Immunization[]
    isLoading: boolean
    onCreateSuccess: (data: Bundle) => void
    onEditSuccess: (data: Immunization) => void

    defaultSelected?: boolean
    isRecommended?: boolean
    supportText?: string

    onCheckboxClick?: (
        evt: React.MouseEvent<HTMLInputElement, MouseEvent>
    ) => void
}

export const ImmunizationField: FC<ImmunizationFieldProps> = ({
    label,
    name,
    immunizationCode,
    immunizationDisplay,
    immunizationSystem,
    defaultSelected,
    supportText,
    isRecommended,
    onCheckboxClick,
    immunizations,
    isLoading,
    onCreateSuccess,
    onEditSuccess
}) => {
    const datesName = `${name}-dates`

    const { fields, append, remove } = useFieldArray({
        name: datesName
    })
    const { register, watch, setValue } = useFormContext()

    const isChecked = watch(name)
    const hasFieldsTouchedByProvider = fields.some(
        f => f.lastTouchedByPatient === false
    )

    // This is used to show a small spinner after clicking on the "remove" button
    const [removingId, setRemovingId] = useState<undefined | string>(undefined)

    const { addedImmunization, removedImmunization, editedImmunization } =
        useAmpli()

    const { mutate: createImmunization, isLoading: createIsLoading } =
        useCreateImmunizations({
            onSuccess: onCreateSuccess
        })
    const { mutate: editImmunization, isLoading: editIsLoading } =
        useEditImmunization({
            onSuccess: onEditSuccess
        })

    // The checkbox handler
    const handleOnChange = (
        evt: React.ChangeEvent<HTMLInputElement>,
        field: Partial<ArrayField<Record<string, any>, 'id'>>
    ) => {
        const value = new Date(evt.target.value)

        if (!(value instanceof Date) || isNaN(value.getTime())) {
            // Invalid date
            return
        }

        if (value.getUTCFullYear() <= 1900) {
            // Too old... probably wrong date... just ignore
            return
        }

        const immunization = immunizations.find(
            x => x.id === field.immunizationId
        )
        if (!immunization) {
            // CREATE
            createImmunization([
                {
                    occurrenceDateTime: value.toISOString(),
                    resourceType: 'Immunization',
                    status: 'completed',
                    vaccineCode: {
                        coding: [
                            {
                                code: immunizationCode,
                                display: immunizationDisplay,
                                system: immunizationSystem
                            }
                        ],
                        text: immunizationDisplay
                    }
                }
            ])
            addedImmunization({
                isRecommended: !!isRecommended
            })
        } else {
            // EDIT
            if (
                immunization.occurrenceDateTime &&
                new Date(immunization.occurrenceDateTime) === value
            ) {
                // No changes
                return
            }

            editImmunization({
                ...immunization,
                occurrenceDateTime: value.toISOString(),
                occurrenceString: undefined
            })
            editedImmunization({
                IsRecommended: !!isRecommended
            })
        }
    }

    const removeImmunization = (index: number, immunization?: Immunization) => {
        if (immunization) {
            setRemovingId(immunization.id)

            removedImmunization({
                IsRecommended: !!isRecommended
            })
            editImmunization({
                ...immunization,
                status: 'entered-in-error'
            })
        } else {
            remove(index)
        }
    }

    const handleRemoveClick = (
        index: number,
        field: Partial<ArrayField<Record<string, any>, 'id'>>
    ) => {
        const immunization = immunizations.find(
            x => x.id === field.immunizationId
        )
        removeImmunization(index, immunization)
    }

    const onCheckboxChange = (
        evt: React.MouseEvent<HTMLInputElement, MouseEvent>
    ) => {
        onCheckboxClick && onCheckboxClick(evt)
        const checked = (evt.target as any).checked
        // Uncheck
        if (checked) {
            return
        }

        if (fields.length === 0) {
            return
        }

        const immunizationsToRemove = immunizations.filter(immunization =>
            fields.some(field => field.immunizationId === immunization.id)
        )

        for (const immunization of immunizationsToRemove) {
            const index = fields.findIndex(
                field => field.immunizationId === field.id
            )
            removeImmunization(index, immunization)
        }
    }

    useLayoutEffect(() => {
        if (isLoading || createIsLoading || editIsLoading) {
            return
        }

        if (defaultSelected && !isChecked) {
            setValue(name, true)
        }

        if (!editIsLoading) {
            setRemovingId(undefined)
        }

        remove()
    }, [
        immunizationCode,
        immunizations,
        isLoading,
        isChecked,
        createIsLoading,
        editIsLoading
    ])

    useLayoutEffect(() => {
        const dates = immunizations
            .filter(immunization => immunization.status === 'completed')
            .filter(
                (x: Immunization) =>
                    x.vaccineCode?.coding?.[0]?.code?.toString() ===
                    immunizationCode?.toString()
            )
            .map((x: Immunization) => {
                const date = x.occurrenceDateTime
                return {
                    value: date ? date.split('T')[0] : date,
                    valueLegacy: x.occurrenceString,
                    immunizationId: x.id,
                    lastTouchedByPatient: lastTouchedByPatient(x),
                    lastUpdatedAt: x?.meta?.lastUpdated
                }
            })
            .sort((left, right) => {
                if (!right.value) {
                    return left.value ? -1 : 0
                }

                if (!left.value) {
                    return 1
                }

                return right.value.localeCompare(left.value, 'en', {
                    sensitivity: 'base'
                })
            })

        if (dates.length > 0 && fields.length < dates.length) {
            dates.forEach(date => append(date))
        }

        if (isChecked && dates.length === 0 && fields.length === 0) {
            append({ value: '', lastTouchedByPatient: true })
        }
    }, [fields])

    return (
        <>
            <Label
                sx={{
                    alignItems: 'center',
                    width: '100%',
                    fontSize: 4,
                    cursor: 'pointer'
                }}
            >
                <Checkbox
                    pretty
                    sx={{
                        'minWidth': 'auto',
                        'input:disabled ~ div': (t: ThemeUI.Theme) => ({
                            background: t.colors?.inactiveButton
                        })
                    }}
                    name={name}
                    onClick={onCheckboxChange}
                    disabled={hasFieldsTouchedByProvider}
                />
                {label}
            </Label>
            {supportText && (
                <Label
                    sx={{
                        width: 'auto',
                        margin: 0,
                        fontSize: 0,
                        color: 'supportText',
                        marginLeft: 56
                    }}
                >
                    {supportText}
                </Label>
            )}

            {isChecked &&
                fields.map((field, i) => (
                    <Box key={field.id}>
                        <Label sx={{ mt: 4 }}>Date</Label>
                        <ThemeUI.Input
                            type={
                                field.valueLegacy && !field.lastTouchedByPatient
                                    ? 'text'
                                    : 'date'
                            }
                            ref={register({
                                required: field.lastTouchedByPatient
                            })}
                            name={`${datesName}.${i}.value`}
                            defaultValue={field.valueLegacy || field.value}
                            disabled={!field.lastTouchedByPatient}
                            onChange={evt => handleOnChange(evt, field)}
                            placeholder={'mm/dd/yyyy'}
                            sx={{
                                'mb': 0,
                                'color': 'text',
                                '::-webkit-calendar-picker-indicator': {
                                    filter: 'invert(71%) sepia(2%) saturate(5054%) hue-rotate(319deg) brightness(92%) contrast(68%)'
                                },
                                ':disabled': {
                                    'borderColor': undefined,
                                    '::-webkit-calendar-picker-indicator': {
                                        visibility: 'visible',
                                        filter: 'invert(70%) sepia(5%) saturate(1779%) hue-rotate(314deg) brightness(91%) contrast(77%)'
                                    }
                                }
                            }}
                        />
                        {fields.length > 1 &&
                            field.lastTouchedByPatient &&
                            ((!createIsLoading && !editIsLoading) ||
                                removingId !== field.immunizationId) && (
                                <Label
                                    sx={{
                                        mt: 1,
                                        mb: 0,
                                        fontSize: 0,
                                        color: 'raspberry',
                                        textDecoration: 'underline',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => handleRemoveClick(i, field)}
                                >
                                    Remove
                                </Label>
                            )}
                        {fields.length > 1 &&
                            field.lastTouchedByPatient &&
                            (createIsLoading || editIsLoading) &&
                            removingId === field.immunizationId && (
                                <Spinner
                                    sx={{ justifyContent: 'left', height: 5 }}
                                />
                            )}

                        {field.lastUpdatedAt && !field.lastTouchedByPatient && (
                            <Text
                                sx={{
                                    mt: 1,
                                    fontSize: 0,
                                    fontWeight: 700,
                                    color: 'supportText'
                                }}
                            >
                                last created or updated by the care team on{' '}
                                {format(
                                    new Date(field.lastUpdatedAt),
                                    'MM-dd-yyyy'
                                )}
                            </Text>
                        )}
                    </Box>
                ))}

            {isChecked && (
                <Flex
                    sx={{
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        mt: 2
                    }}
                >
                    <Label
                        onClick={() => {
                            append({
                                value: '',
                                lastTouchedByPatient: true
                            })
                        }}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: 'fit-content',
                            color: 'raspberry',
                            cursor: 'pointer',
                            fontSize: 0,
                            mb: 0
                        }}
                    >
                        <PlusIcon color="raspberry" sx={{ mr: 2 }} /> Add
                        another
                    </Label>
                </Flex>
            )}
            <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
        </>
    )
}
