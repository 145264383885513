import { Link } from '@asktia/tia-ui'
import { FilterModalProps } from 'src/flows/AppointmentBooking/views/BookingTimeSelection/AvailableSlotsFilter'
import { useAmpli } from 'src/hooks'
import { CheckboxFilterModal } from './CheckboxFilterModal'

export const RaceEthnicityFilterModal = (props: FilterModalProps<string[]>) => {
    const options = [
        { key: 'any', label: 'Any race or ethnicity' },
        { key: 'bipoc', label: 'BIPOC (Black, indigenous, people of color)' },
        { key: 'white', label: 'White' },
        { key: 'hispanic_latinx', label: 'Hispanic or Latinx' },
        {
            key: 'black_african_american',
            label: 'Black or African American'
        },
        { key: 'asian', label: 'Asian' }
    ]

    const { appointmentFilteredByEthnicity } = useAmpli()

    function onSelected(ethnicities: string[]) {
        appointmentFilteredByEthnicity(
            ethnicities.sort().join(', '),
            props.appointmentProfile
        )
        props.onSelected(ethnicities)
    }

    return (
        <CheckboxFilterModal
            {...props}
            onSelected={onSelected}
            title="Race & ethnicity"
            description="While all Tia therapists are trained to deliver culturally-tailored care that recognizes how race and ethnicity impact health, wellbeing, and access to mental healthcare, we also recognize that some patients prefer a therapist with a shared identity or lived experience."
            label="Select race & ethnicity preferences (you can choose more than one):"
            options={options}
            footer={
                <>
                    You can read more about how Tia incorporates the lived
                    experience into our practice at{' '}
                    <Link
                        target="_blank"
                        href="https://asktia.com/care-philosophy"
                    >
                        asktia.com/care-philosophy
                    </Link>
                    .
                </>
            }
            anyKey="any"
        />
    )
}
