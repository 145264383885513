import { TiaModal, Image, Text, Button, Grid } from '@asktia/tia-ui'
import { ModalTitle } from 'src/components/Blocks'
import findingTheRightImage from 'src/assets/finding-the-right-fit.png'

export const FindingTheRightFit = (props: { hideModal: Function }) => {
    return (
        <TiaModal>
            <ModalTitle
                title="Finding the right fit"
                hideModal={props.hideModal}
            />
            <Grid sx={{ gap: 4, justifyItems: 'center' }}>
                <Image sx={{ width: '224px' }} src={findingTheRightImage} />
                <Text sx={{ fontSize: 2, color: 'grey70' }}>
                    Tia believes that personalization and patient choice are
                    critical to high quality healthcare. That's why we've
                    curated and vetted a team of therapists with diverse
                    backgrounds and areas of expertise for you to choose from.
                    From communication style to gender to race and ethnicity,
                    you can choose a therapist that best meets your needs.
                </Text>
                <Text sx={{ fontSize: 2, color: 'grey70' }}>
                    If after your first appointment, you're not feeling a
                    "match," we'll help you find a better fit!
                </Text>
                <Button
                    fullWidth
                    type="submit"
                    onClick={() => props.hideModal()}
                >
                    Got it!
                </Button>
            </Grid>
        </TiaModal>
    )
}
