import { FilterModalProps } from 'src/flows/AppointmentBooking/views/BookingTimeSelection/AvailableSlotsFilter'
import { useAmpli } from 'src/hooks'
import { CheckboxFilterModal } from './CheckboxFilterModal'

export const CommunicationStyleFilterModal = (
    props: FilterModalProps<string[]>
) => {
    const options = [
        {
            key: 'active',
            label: 'Active',
            prompt: 'Prompts with a lot of questions'
        },
        {
            key: 'passive',
            label: 'Passive',
            prompt: 'Listens for most of the session'
        },
        {
            key: 'informational',
            label: 'Informational',
            prompt: 'Provides psycho-education'
        },
        {
            key: 'humorous',
            label: 'Humorous',
            prompt: 'Uses humor, as appropriate'
        }
    ]

    const { appointmentFilteredByCommunicationStyle } = useAmpli()

    function onSelected(commStyles: string[]) {
        appointmentFilteredByCommunicationStyle(
            commStyles.sort().join(', '),
            props.appointmentProfile
        )
        props.onSelected(commStyles)
    }

    return (
        <CheckboxFilterModal
            {...props}
            onSelected={onSelected}
            title="Communication style"
            description="All Tia therapists are trained to deliver affirming, non-judgmental mental healthcare tailored to you. We offer options so you can select a therapeutic communication style that works best for you and your needs."
            label="Select any preferred communication styles (you can choose more than one):"
            options={options}
        />
    )
}
